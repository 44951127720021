/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroEducationType from 'elements/Hero/HeroEducationType'
import Breadcrumb from 'elements/Breadcrumb'
import ExtraContent from 'elements/ExtraContent'
// import ParticipantStory from 'elements/Education/ParticipantStory'
import MoreEducations from 'elements/Education/MoreEducations'
import Filter from 'elements/Education/Filter'

// Actions
import AskAdvice from 'actions/AskAdvice'

// Shared
import ParseContent from 'shared/ParseContent'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'

// Third Party
import styled from 'styled-components'

const Row = styled.div`
  margin-bottom: -200px;

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`

const Content = styled(ParseContent)`
  h2 {
    font-weight: ${(props) => props.theme.font.weight.xl};
  }
`

const PageTemplate = ({
  data: {
    typePage: { paginaOpleidingenType: acf, seo },
    page: { name, slug, categorieOpleidingType: pageAcf },
  },
  pageContext,
}) => {
  const [isScrollable, setIsScrollable] = useState(true)

  return (
    <Layout isScrollable={isScrollable}>
      <SEO seo={seo} />

      <HeroEducationType title={name} fields={pageAcf} />

      <Breadcrumb data={name} className="py-2" pageContext={pageContext} />

      <div className="container py-lg-5 py-3">
        <Row className="row">
          <div className="col-lg-6">
            <Content content={pageAcf.description} />
          </div>
          <div className="col-lg-6 pb-lg-0 pb-4 pt-lg-0 pt-3 d-flex justify-content-end">
            <AskAdvice />
          </div>
        </Row>
      </div>

      <div className="py-lg-5 py-3" />
      <div className="py-lg-5 py-3" />

      <Filter
        setIsScrollable={setIsScrollable}
        beginFilter={[name]}
        type={slug}
      />

      {/* <ParticipantStory className="py-lg-5 py-3" /> */}

      <MoreEducations className="py-lg-5 py-3" />

      <ExtraContent content={acf.paginaopleidingentyperelation} />

      <div className="d-md-block d-none" style={{ height: '100px' }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpOpleidingType(databaseId: { eq: $wordpress_id }) {
      name
      slug
      uri

      categorieOpleidingType {
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 1920)
            }
          }
        }
      }
    }

    typePage: wpPage(databaseId: { eq: 464 }) {
      paginaOpleidingenType {
        paginaopleidingentyperelation {
          ... on WpComponent {
            slug
          }
        }
      }

      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default PageTemplate
