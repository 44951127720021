import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Fan from 'img/icon/fan.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import BackgroundHexagon from 'components/elements/BackgroundHexagon'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'
import parse from 'html-react-parser'

const Education = styled(motion.div)`
  width: 100%;
  height: 75px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  background-color: ${(props) => props.theme.color.face.light};
  padding: 0 10px 0 10px;

  p {
    font-size: ${(props) => props.theme.font.size.ms};
    font-weight: ${(props) => props.theme.font.weight.l};
    color: ${(props) => props.theme.color.text.secondary};

    &:hover {
      text-decoration: none;
    }
  }
`

const EducationsTitle = styled.h2`
  font-size: ${(props) => props.theme.font.size.lm};
  font-weight: ${(props) => props.theme.font.weight.xl};
  color: ${(props) => props.theme.color.text.secondary};
`

const StyledMoreEducations = styled.div``

const MoreEducations = ({ className }) => {
  const {
    types: { nodes: types },
  } = useStaticQuery(graphql`
    {
      types: allWpOpleidingType {
        nodes {
          count
          databaseId
          name
          slug
          id
          uri
        }
      }
    }
  `)

  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledMoreEducations
      className={`position-relative ${className ? `${className}` : ``}`}
    >
      <BackgroundHexagon noMobile position="bottom-right" />
      <div className="container">
        <div className="row justify-content-center justify-content-lg-end">
          <div className="col-6 col-sm-3 py-2 d-flex align-items-center px-2">
            <EducationsTitle>
              MEER
              <br />
              OPLEIDINGEN
            </EducationsTitle>
          </div>
          {types.map((e, index) => (
            <CustomLink
              to={e.uri}
              key={e.databaseId}
              className="position-relative col-6 col-sm-3 py-2 d-flex px-2"
            >
              <Education
                ref={ref}
                initial={{ opacity: 0.3, y: 50 }}
                animate={
                  isVisible || view
                    ? {
                        opacity: 1,
                        y: 0,
                        transition: { delay: index * 0.1, type: 'spring' },
                      }
                    : { opacity: 0.3, y: 50 }
                }
                whileHover={{
                  y: -5,
                  transition: { type: 'spring', duration: 0.3, delay: 0 },
                }}
                className="d-flex align-items-center"
              >
                <div>
                  <Fan className="mb-2" />
                  <p className="mb-0">{`${parse(e.name)} (${e.count})`}</p>
                </div>
              </Education>
            </CustomLink>
          ))}
        </div>
      </div>
    </StyledMoreEducations>
  )
}

export default MoreEducations
