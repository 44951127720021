/* eslint-disable import/no-unresolved */
import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Hero = styled(Plaatjie)`
  height: 100%;
  position: absolute !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

const Content = styled(ParseContent)`
  position: relative;
  top: 50px;
  color: ${(props) => props.theme.color.text.light};
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  h1,
  h2 {
    font-weight: ${(props) => props.theme.font.weight.xl};
    font-size: ${(props) => props.theme.font.size.xxml};
    letter-spacing: 2px;

    @media screen and (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size.xl};
      text-transform: uppercase;
      word-break: break-all;
    }
  }

  p {
    @media screen and (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size.sm};
    }
  }
`

const StyledHeroDefault = styled.div`
  position: relative;
  height: 500px;

  @media screen and (max-width: 991px) {
    height: 400px;
  }

  @media screen and (max-width: 767px) {
    height: 300px;
  }
`

const HeroDefaultWrapper = styled(motion.div)``

const HeroEducationType = ({ title, fields }) => (
  <StyledHeroDefault>
    <HeroDefaultWrapper initial={{ height: '100%' }}>
      <Hero image={fields.image} aboveFold />
      <div className="container h-100 py-5">
        <div className="d-flex h-100 align-items-center">
          <Content content={`<h2>${title}</h2>`} />
        </div>
      </div>
    </HeroDefaultWrapper>
  </StyledHeroDefault>
)

export default HeroEducationType
